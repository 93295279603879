import { createStore } from 'vuex'

export default createStore({
  state: {
    lang: 'en',
    showForm: false,
  },
  getters: {
    getLang(state) {
      return state.lang
    },
    showForm(state) {
      return state.showForm
    }
  },
  mutations: {
    setLang(state, lang) {
      localStorage.setItem('lang', lang)
      state.lang = lang
    },
    setForm(state, view) {
      return state.showForm = view
    }
  },
  actions: {
  },
  modules: {
  }
})
