<template>
<!--    :style="{-->
<!--    backgroundImage: `url('`+ require('@/assets/' + icon.name + '.png') + `')`,-->
<!--    backgroundRepeat: 'no-repeat'-->
<!--    }"-->
    <section class="section-product"
             :class="icon.class"
             :id="id"
    >
        <div class="wrap section-product__block">
            <div class="section-product__item section-product__content">
                <h3 class="section-product__title">{{ title }}</h3>
                <h4 class="section-product__subtitle discount">
                    <template v-if="Array.isArray(subtitle)">
                        <span v-for="(title, i) in subtitle" :key="i" class="section-product__subtitle-item">
                            <span class="section-product__subtitle-badge"></span>
                            <span class="section-product__subtitle-item-text">{{ title[getLang] }}</span>
                        </span>
                    </template>
                    <template v-else>
                        {{ subtitle[getLang] }}
                    </template>
                </h4>
                <p class="section-product__description" v-if="description">{{ description[getLang] }}</p>
                <div class="section-product__img">
                    <img :src="require('@/assets/' + icon.name /*(!isMobile ? icon.name : (icon?.mobile || icon.name))*/ + '.png')" alt="">
                </div>
                <div class="section-product__buttons">
                    <button
                        v-for="(button, i) in buttons"
                        class="button"
                        :href="button.url"
                        :class="[(i%2 && 'button__base') || 'button__uotline']"
                        :key="i"
                        @click="setForm(true)"
                    >
                        {{ button.title[getLang] }}
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
    name: "ProductInfo",
    props: {
        value: Object,
    },
    data() {
        return {
            isMobile: null
        }
    },
    computed: {
        ...mapGetters([
            "getLang"
        ]),
        id() {
            return this.value.id
        },
        icon() {
            return this.value?.icon
        },
        title() {
            return this.value?.title[this.getLang]
        },
        subtitle() {
            return this.value?.subtitle
        },
        description() {
            return this.value?.description
        },
        buttons() {
            return this.value?.buttons
        }
    },
    methods: {
        ...mapMutations([
            "setForm"
        ]),
        mobile() {
           this.isMobile = window.innerWidth < 700
        }
    },
    created() {
        this.mobile()
        window.addEventListener('resize', this.mobile, true)
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.mobile, true)
    },
}
</script>

<style scoped>

</style>