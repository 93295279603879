<template>
    <footer class="footer">
        <div class="wrap">
            <section class="footer__links">
                <a class="footer__logo" href="/">
                    <img :src="require('@/assets/logo.png')" alt="">
                </a>
                <div class="footer__links-container">
                    <ul class="footer__links-list">
                        <li v-for="(product, i) in products"
                            :key="i"
                            class="footer__links-item"
                        >
                            <a class="footer__links-link" @click="setLink(product.url)">{{ product.title }}</a>
                        </li>
                    </ul>
                    <SocialNetwork/>
                </div>
            </section>
        </div>
        <section class="footer-copyright">
            <div class="wrap footer-copyright__wrap">
                <div class="footer-copyright__text">
                    Copyright 2020. All right reserved
                </div>
                <ul class="footer-copyright__list">
                    <li v-for="(item, i) in copyrightList"
                        :key="i"
                        class="footer-copyright__item"
                    >
                        <a :href="item.url" target="_blank" class="footer-copyright__link">
                            <span class="footer-copyright__badge" :class="'footer-copyright__badge__'+item.icon"></span>
                            {{ item.title }}
                        </a>
                    </li>
                </ul>
            </div>
        </section>
        <div class="gradient-line"></div>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent",
    components: {},
    data() {
        return {
            products: [
                {
                    url: '#vision',
                    title: 'Vision'
                },
                {
                    url: '#track',
                    title: 'Track'
                },
                {
                    url: '#cargo',
                    title: 'Cargo'
                },
                {
                    url: '#iot',
                    title: 'Mobile'
                },
                // {
                //     url: '#data',
                //     title: 'xData'
                // },
            ],
            copyrightList: [
                {
                    url: 'https://app.termly.io/document/privacy-policy/fbe2ec50-0ea0-4310-a1b3-bc38242ebfca',
                    icon: 'policy',
                    title: 'Privacy Policy'
                },
                {
                    url: 'https://app.termly.io/document/cookie-policy/06a53cd6-29ef-4b8b-bc6d-d8b8702ab2b1',
                    icon: 'cookies',
                    title: 'Cookies Policy'
                },
                {
                    url: 'https://app.termly.io/document/terms-of-service/58980bdd-2b5e-4911-9aa5-5d43ae8c6189',
                    icon: 'conditions',
                    title: 'Terms and Conditions'
                },

                // {
                //     url: 'https://app.termly.io/document/eula/2d5a9148-fcb4-42b4-ad89-c35e35f78803',
                //     icon: '',
                //     title: 'EULA'
                // },
                // {
                //     url: 'https://app.termly.io/document/refund-policy/36d0ab5c-27ce-47bc-9aea-8078f1662f58',
                //     icon: '',
                //     title: 'Return Policy'
                // },
                // {
                //     url: 'https://app.termly.io/document/disclaimer/9557fd7b-bb7e-4889-b89f-5d8e11efe61f',
                //     icon: '',
                //     title: 'Disclaimer'
                // },
                // {
                //     url: null,
                //     icon: '',
                //     title: 'Shipping Policy'
                // },
                // {
                //     url: 'https://app.termly.io/document/acceptable-use/7c53c626-37f1-44f3-a149-810259e2c1f0',
                //     icon: '',
                //     title: 'Acceptable Use Policy'
                // },
            ]
        }
    },
    methods: {
        setLink(link) {
            document.querySelector(link).scrollIntoView({ behavior : "smooth" , block: "center"})
        },
    }
}
</script>

<style scoped>

</style>