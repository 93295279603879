import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Main',
    component: HomeView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to) => {
  if(to.name == 'Main' && to.path != '/'){
    return '/'
  }
  return true
})

export default router
