<template>
    <HeaderComponent/>
    <main class="main">
        <router-view/>
    </main>
    <FooterComponent/>
    <ContactForm v-if="showForm"/>
</template>

<style lang="scss">
@import "scss/main.scss";
</style>
<script>
import {mapGetters, mapMutations} from "vuex";
export default {
    name: "App",
    components: {},
    data() {
        return {
        }
    },
    computed:{
        ...mapGetters([
            "getLang",
            "showForm",
        ]),
    },
    methods: {
      ...mapMutations([
          "setLang"
      ])
    },
    created() {
        let lang = localStorage.getItem('lang');
        this.setLang(lang || this.getLang)
    }
}
</script>